<template>
	<!-- begin::page-container(#회원가입) -->
	<main class="page-container selfdx-input" id="selfdxInput3">
		<div class="container">
			<div class="selfdx-input-header">
				<h2 class="title">지원사업 관심분야 및 사업 역량 진단을 진행해 주세요.</h2>
			</div>

			<!-- s: section -->
			<section class="selfdx-input-section">
				<div class="selfdx-input-section__header">
					<h3 class="title">추가정보 입력</h3>
				</div>
				<div class="selfdx-input-section__content">
					<dl class="selfdx-input-question bottom-border">
						<dt>지원사업의 관심 주제를 모두 선택하세요.</dt>
						<dd>
							<div class="control-flex">
								<div class="checkbox is-style is-twoline" v-for="(item, idx) in checkbox1" :key="idx">
									<input type="checkbox" name="checkbox1" ref="diffFields" :id="'diffFields_' + idx" :value="item.value" v-model="params.diagnDiffFields" />
									<label :for="'diffFields_' + idx">{{ item.label }}</label>
								</div>
							</div>
						</dd>
					</dl>
					<dl class="selfdx-input-question">
						<dt>나의 경영실력/지식은 어느 정도라고 생각하세요?</dt>
						<dd>
							<div class="control-row">
								<div class="radio is-normal" v-for="(item, idx) in radioIconManage" :key="idx">
									<input type="radio" name="radio1" ref="manageScore" :id="'manage_' + idx" :value="item.value" v-model="params.manageScore" />
									<label :for="'manage_' + idx" v-html="item.label"></label>
								</div>
							</div>
						</dd>
					</dl>
					<dl class="selfdx-input-question">
						<dt>나의 마케팅 능력은 어느 정도라고 생각하세요?</dt>
						<dd>
							<div class="control-row">
								<div class="radio is-normal" v-for="(item, idx) in radioIconMarketing" :key="idx">
									<input type="radio" name="radio2" ref="marketingScore" :id="'marketing_' + idx" :value="item.value" v-model="params.marketingScore" />
									<label :for="'marketing_' + idx" v-html="item.label"></label>
								</div>
							</div>
						</dd>
					</dl>
					<dl class="selfdx-input-question">
						<dt>유사상품 대비 내 아이템의 경쟁력은 어느 정도라고 생각하세요?</dt>
						<dd>
							<div class="control-row">
								<div class="radio is-normal" v-for="(item, idx) in radioIconItem" :key="idx">
									<input type="radio" name="radio3" ref="itemScore" :id="'item_' + idx" :value="item.value" v-model="params.itemScore" />
									<label :for="'item_' + idx" v-html="item.label"></label>
								</div>
							</div>
						</dd>
					</dl>
					<dl class="selfdx-input-question">
						<dt>나의 재무상태는 어느 정도라고 생각하세요?</dt>
						<dd>
							<div class="control-row">
								<div class="radio is-normal" v-for="(item, idx) in radioIconFinance" :key="idx">
									<input type="radio" name="radio4" ref="financeScore" :id="'finance_' + idx" :value="item.value" v-model="params.financeScore" />
									<label :for="'finance_' + idx" v-html="item.label"></label>
								</div>
							</div>
						</dd>
					</dl>
					<dl class="selfdx-input-question">
						<dt>나의 상권/입지는 어느 정도라고 생각하세요?</dt>
						<dd>
							<div class="control-row">
								<div class="radio is-normal" v-for="(item, idx) in radioIconLocation" :key="idx">
									<input type="radio" name="radio5" ref="locationScore" :id="'location_' + idx" :value="item.value" v-model="params.locationScore" />
									<label :for="'location_' + idx" v-html="item.label"></label>
								</div>
							</div>
						</dd>
					</dl>
					<dl class="selfdx-input-question">
						<dt>사업을 위한 준비과정 및 상태는 어느 정도라고 생각하세요?</dt>
						<dd>
							<div class="control-row">
								<div class="radio is-normal" v-for="(item, idx) in radioIconReady" :key="idx">
									<input type="radio" name="radio6" ref="readyScore" :id="'ready_' + idx" :value="item.value" v-model="params.readyScore" />
									<label :for="'ready_' + idx" v-html="item.label"></label>
								</div>
							</div>
						</dd>
					</dl>
				</div>
			</section>
			<!-- e: section -->
			<div class="buttons selfdx-input-footer">
				<button type="button" class="button-default is-large is-gray is-rounded" @click="back">이전단계</button>
				<button type="button" class="button-default is-large is-secondary is-rounded" @click="complete">진단하기</button>
			</div>
		</div>
	</main>
</template>

<script>
import { MUT_SHOW_ALERT } from '@/store/_mut_consts';
import { DiffField, Score, ScoreFinance, ScoreItem, ScoreLocation, ScoreManage, ScoreMarketing, ScoreReady } from '../../../store/modules/diagn';

const inputData = {
	diagnDiffFields: [],
	manageScore: 0,
	marketingScore: 0,
	itemScore: 0,
	financeScore: 0,
	locationScore: 0,
	readyScore: 0,
};
export default {
	name: 'SelfDiagnosisInput3',
	components: {},
	props: {
		filter: inputData,
	},
	data: () => ({
		checkbox1: DiffField,
		radioIconManage: ScoreManage,
		radioIconMarketing: ScoreMarketing,
		radioIconItem: ScoreItem,
		radioIconFinance: ScoreFinance,
		radioIconLocation: ScoreLocation,
		radioIconReady: ScoreReady,
		params: inputData,
	}),
	watch: {
		filter: {
			handler(newVal, _) {
				this.updateData(newVal);
			},
			immediate: true,
			deep: true,
		},
		params: {
			handler(newVal, _) {
				this.sync(newVal);
			},
			immediate: true,
			deep: true,
		},
	},
	created() {},
	methods: {
		updateData(newVal) {
			if (!newVal) return;
			Object.assign(this.params, newVal);

			if (!this.params.manageScore) {
				this.params.manageScore = 3;
			}
			if (!this.params.marketingScore) {
				this.params.marketingScore = 3;
			}
			if (!this.params.itemScore) {
				this.params.itemScore = 3;
			}
			if (!this.params.financeScore) {
				this.params.financeScore = 3;
			}
			if (!this.params.locationScore) {
				this.params.locationScore = 3;
			}
			if (!this.params.readyScore) {
				this.params.readyScore = 3;
			}
		},
		validation() {
			if (!this.params.diagnDiffFields.length) {
				this.$store.commit(`common/${MUT_SHOW_ALERT}`, { 
					title: '지원사업의 관심 주제를 선택해주세요.'
					, yesfunc: () => {
						if(this.$refs.diffFields.length) this.$refs.diffFields[0].focus();
					},
				 });
				return false;
			}
			if (!this.params.manageScore) {
				this.$store.commit(`common/${MUT_SHOW_ALERT}`, { 
					title: '나의 경영실력/지식을 선택해주세요.'
					, yesfunc: () => {
						if(this.$refs.manageScore.length) this.$refs.manageScore[0].focus();
					},
				 });
				return false;
			}
			if (!this.params.marketingScore) {
				this.$store.commit(`common/${MUT_SHOW_ALERT}`, { 
					title: '내 아이템의 경쟁력/차별화를 선택해주세요.'
					, yesfunc: () => {
						if(this.$refs.marketingScore.length) this.$refs.marketingScore[0].focus();
					},
				 });
				return false;
			}
			if (!this.params.itemScore) {
				this.$store.commit(`common/${MUT_SHOW_ALERT}`, { 
					title: '나의 재무상태를 선택해주세요.'
					, yesfunc: () => {
						if(this.$refs.itemScore.length) this.$refs.itemScore[0].focus();
					},
				 });
				return false;
			}
			if (!this.params.financeScore) {
				this.$store.commit(`common/${MUT_SHOW_ALERT}`, { 
					title: '나의 상권/입지를 선택해주세요.'
					, yesfunc: () => {
						if(this.$refs.financeScore.length) this.$refs.financeScore[0].focus();
					},
				 });
				return false;
			}
			if (!this.params.locationScore) {
				this.$store.commit(`common/${MUT_SHOW_ALERT}`, { 
					title: '사업을 위한 준비과정 및 상태를 선택해주세요.'
					, yesfunc: () => {
						if(this.$refs.locationScore.length) this.$refs.locationScore[0].focus();
					},
				 });
				return false;
			}
			if (!this.params.readyScore) {
				this.$store.commit(`common/${MUT_SHOW_ALERT}`, { 
					title: '나의 경영실력/지식을 선택해주세요.'
					, yesfunc: () => {
						if(this.$refs.readyScore.length) this.$refs.readyScore[0].focus();
					},
				 });
				return false;
			}
			return true;
		},
		back() {
			this.$emit('back');
		},
		sync() {
			this.$emit('sync', this.params);
		},
		complete() {
			const isVadt = this.validation();
			if (isVadt) {
				// console.log('뎁스3 : ', this.params)
				this.$emit('complete', this.params);
			}
		},
	},
};
</script>
