<template>
	<!-- begin::page-container(#회원가입) -->
	<main class="page-container selfdx-input" id="selfdxInput4">
		<div class="container">
			<div class="selfdx-input-header">
				<h2 class="title">
					자가진단 서비스 품질향상에 도움이 되는 항목입니다.<br />
					해당사항이 없으실 경우 다음 단계로 이동하세요.
				</h2>
			</div>
			<!-- 건너뛰기 버튼 -->
			<div class="buttons selfdx-input-skip">
				<button type="button" class="button-default is-large is-gray is-rounded" @click="jump">이 단계 건너뛰기</button>
			</div>
			<!-- s: section -->
			<section class="selfdx-input-section">
				<div class="selfdx-input-section__header">
					<h3 class="title">선택정보 입력</h3>
				</div>
				<div class="selfdx-input-section__content">
					<dl class="selfdx-input-question">
						<dt>온라인을 통한 판매경험이 있으신가요?</dt>
						<dd>
							<div class="control-flex">
								<div class="radio is-style" v-for="(item, idx) in radio1" :key="idx">
									<input type="radio" name="radio1" ref="radio1" :id="'onlineSaleYn_' + idx" v-model="params.onlineSaleYn" :value="item.value" />
									<label :for="'onlineSaleYn_' + idx">{{ item.label }}</label>
								</div>
							</div>
						</dd>
					</dl>
					<!-- s: 판매경험 예 선택시 -->
					<div v-if="params.onlineSaleYn === 'Y'" class="selfdx-input-question">
						<section class="selfdx-input-question">
							<h4 class="selfdx-input-question__title">판매 경험이 있으시다면, 해당되는 채널을 모두 선택해 주세요.</h4>
							<dl class="selfdx-input-question">
								<dt class="is-small">① 온라인 쇼핑몰</dt>
								<dd>
									<div class="control-flex">
										<div class="checkbox is-style" v-for="(item, idx) in checkbox1" :key="idx">
											<input type="checkbox" name="checkbox1" ref="checkbox1" :id="'shop1_' + idx" :value="item.value" v-model="params.onlineNowChnl" />
											<label :for="'shop1_' + idx">{{ item.label }}</label>
										</div>
									</div>
								</dd>
							</dl>
							<dl class="selfdx-input-question">
								<dt class="is-small">② TV 홈쇼핑</dt>
								<dd>
									<div class="control-flex">
										<div class="checkbox is-style" v-for="(item, idx) in checkbox1" :key="idx">
											<input type="checkbox" name="checkbox2" ref="checkbox2" :id="'shop2_' + idx" :value="item.value" v-model="params.onlineNowTv"/>
											<label :for="'shop2_' + idx">{{ item.label }}</label>
										</div>
									</div>
								</dd>
							</dl>
							<dl class="selfdx-input-question">
								<dt class="is-small">③ 미디어커머스</dt>
								<dd>
									<div class="control-flex">
										<div class="checkbox is-style" v-for="(item, idx) in checkbox1" :key="idx">
											<input type="checkbox" name="checkbox3" ref="checkbox3" :id="'shop3_' + idx" :value="item.value" v-model="params.onlineNowMid"/>
											<label :for="'shop3_' + idx">{{ item.label }}</label>
										</div>
									</div>
								</dd>
							</dl>
							<dl class="selfdx-input-question">
								<dt class="is-small">④ 라이브커머스</dt>
								<dd>
									<div class="control-flex">
										<div class="checkbox is-style" v-for="(item, idx) in checkbox1" :key="idx">
											<input type="checkbox" name="checkbox4" ref="checkbox4" :id="'shop4_' + idx" :value="item.value" v-model="params.onlineNowLiv"/>
											<label :for="'shop4_' + idx">{{ item.label }}</label>
										</div>
									</div>
								</dd>
							</dl>
							<dl class="selfdx-input-question">
								<dt class="is-small">⑤ 정기구독</dt>
								<dd>
									<div class="control-flex">
										<div class="checkbox is-style" v-for="(item, idx) in checkbox1" :key="idx">
											<input type="checkbox" name="checkbox5" ref="checkbox5" :id="'shop5_' + idx" :value="item.value" v-model="params.onlineNowSub"/>
											<label :for="'shop5_' + idx">{{ item.label }}</label>
										</div>
									</div>
								</dd>
							</dl>
						</section>
						<section class="selfdx-input-question">
							<h4 class="selfdx-input-question__title">추가적으로 판매를 희망하는 온라인 채널을 모두 선택하세요.</h4>
							<dl class="selfdx-input-question">
								<dt class="is-small">① 온라인 쇼핑몰</dt>
								<dd>
									<div class="control-flex">
										<div class="checkbox is-style" v-for="(item, idx) in checkbox1" :key="idx">
											<input type="checkbox" name="checkbox6" ref="checkbox6" :id="'shop6_' + idx" :value="item.value" v-model="params.onlineAditChnl"/>
											<label :for="'shop6_' + idx">{{ item.label }}</label>
										</div>
									</div>
								</dd>
							</dl>
							<dl class="selfdx-input-question">
								<dt class="is-small">② TV 홈쇼핑</dt>
								<dd>
									<div class="control-flex">
										<div class="checkbox is-style" v-for="(item, idx) in checkbox1" :key="idx">
											<input type="checkbox" name="checkbox7" ref="checkbox7" :id="'shop7_' + idx" :value="item.value" v-model="params.onlineAditTv"/>
											<label :for="'shop7_' + idx">{{ item.label }}</label>
										</div>
									</div>
								</dd>
							</dl>
							<dl class="selfdx-input-question">
								<dt class="is-small">③ 미디어커머스</dt>
								<dd>
									<div class="control-flex">
										<div class="checkbox is-style" v-for="(item, idx) in checkbox1" :key="idx">
											<input type="checkbox" name="checkbox8" ref="checkbox8" :id="'shop8_' + idx" :value="item.value" v-model="params.onlineAditMid"/>
											<label :for="'shop8_' + idx">{{ item.label }}</label>
										</div>
									</div>
								</dd>
							</dl>
							<dl class="selfdx-input-question">
								<dt class="is-small">④ 라이브커머스</dt>
								<dd>
									<div class="control-flex">
										<div class="checkbox is-style" v-for="(item, idx) in checkbox1" :key="idx">
											<input type="checkbox" name="checkbox9" ref="checkbox9" :id="'shop9_' + idx" :value="item.value" v-model="params.onlineAditLiv"/>
											<label :for="'shop9_' + idx">{{ item.label }}</label>
										</div>
									</div>
								</dd>
							</dl>
							<dl class="selfdx-input-question">
								<dt class="is-small">⑤ 정기구독</dt>
								<dd>
									<div class="control-flex">
										<div class="checkbox is-style" v-for="(item, idx) in checkbox1" :key="idx">
											<input type="checkbox" name="checkbox10" ref="checkbox10" :id="'shop10_' + idx" :value="item.value" v-model="params.onlineAditSub"/>
											<label :for="'shop10_' + idx">{{ item.label }}</label>
										</div>
									</div>
								</dd>
							</dl>
						</section>
					</div>

					<!-- e: 판매경험 예 선택시 -->
					<!-- s: 판매경험 아니오 선택시 -->
					<div v-if="params.onlineSaleYn === 'N'" class="selfdx-input-question">
						<section class="selfdx-input-question">
							<h4 class="selfdx-input-question__title">판매 경험이 없으시다면, 판매를 희망하는 채널을 모두 선택하세요.</h4>
							<dl class="selfdx-input-question">
								<dt class="is-small">① 온라인 쇼핑몰</dt>
								<dd>
									<div class="control-flex">
										<div class="checkbox is-style" v-for="(item, idx) in checkbox1" :key="idx">
											<input type="checkbox" name="checkbox11" ref="checkbox11" :id="'shop11_' + idx" :value="item.value" v-model="params.onlineHopeChnl"/>
											<label :for="'shop11_' + idx">{{ item.label }}</label>
										</div>
									</div>
								</dd>
							</dl>
							<dl class="selfdx-input-question">
								<dt class="is-small">② TV 홈쇼핑</dt>
								<dd>
									<div class="control-flex">
										<div class="checkbox is-style" v-for="(item, idx) in checkbox1" :key="idx">
											<input type="checkbox" name="checkbox12" ref="checkbox12" :id="'shop12_' + idx" :value="item.value" v-model="params.onlineHopeTv"/>
											<label :for="'shop12_' + idx">{{ item.label }}</label>
										</div>
									</div>
								</dd>
							</dl>
							<dl class="selfdx-input-question">
								<dt class="is-small">③ 미디어커머스</dt>
								<dd>
									<div class="control-flex">
										<div class="checkbox is-style" v-for="(item, idx) in checkbox1" :key="idx">
											<input type="checkbox" name="checkbox13" ref="checkbox13" :id="'shop13_' + idx" :value="item.value" v-model="params.onlineHopeMid"/>
											<label :for="'shop13_' + idx">{{ item.label }}</label>
										</div>
									</div>
								</dd>
							</dl>
							<dl class="selfdx-input-question">
								<dt class="is-small">④ 라이브커머스</dt>
								<dd>
									<div class="control-flex">
										<div class="checkbox is-style" v-for="(item, idx) in checkbox1" :key="idx">
											<input type="checkbox" name="checkbox14" ref="checkbox14" :id="'shop14_' + idx" :value="item.value" v-model="params.onlineHopeLiv"/>
											<label :for="'shop14_' + idx">{{ item.label }}</label>
										</div>
									</div>
								</dd>
							</dl>
							<dl class="selfdx-input-question">
								<dt class="is-small">⑤ 정기구독</dt>
								<dd>
									<div class="control-flex">
										<div class="checkbox is-style" v-for="(item, idx) in checkbox1" :key="idx">
											<input type="checkbox" name="checkbox15" ref="checkbox15" :id="'shop15_' + idx" :value="item.value" v-model="params.onlineHopeSub"/>
											<label :for="'shop15_' + idx">{{ item.label }}</label>
										</div>
									</div>
								</dd>
							</dl>
						</section>
					</div>
					<!-- e: 판매경험 아니오 선택시 -->

					<dl class="selfdx-input-question">
						<dt>해외 판매 경험이 있으신가요?</dt>
						<dd>
							<div class="control-flex">
								<div class="radio is-style" v-for="(item, idx) in radio2" :key="idx">
									<input type="radio" name="radio2" ref="radio2" :id="'globalSaleYn_' + idx" v-model="params.globalSaleYn" :value="item.value"/>
									<label :for="'globalSaleYn_' + idx">{{ item.label }}</label>
								</div>
							</div>
						</dd>
					</dl>
					<!-- s: 해외 판매 경험 예 선택시 -->

					<div v-if="params.globalSaleYn === 'Y'" class="selfdx-input-question">
						<dl class="selfdx-input-question">
							<dt>해외 판매 시, 주로 판매했던 채널을 모두 선택하세요.</dt>
							<dd>
								<div class="control-flex">
									<div class="checkbox is-style" v-for="(item, idx) in checkbox10" :key="idx">
										<input type="checkbox" name="checkbox16" ref="checkbox16" :id="'gshop1_' + idx" :value="item.value" v-model="params.globalNowChnl"/>
										<label :for="'gshop1_' + idx">{{ item.label }}</label>
									</div>
								</div>
							</dd>
						</dl>
						<dl class="selfdx-input-question">
							<dt>추가적으로 판매를 희망하는 채널을 모두 선택하세요.</dt>
							<dd>
								<div class="control-flex">
									<div class="checkbox is-style" v-for="(item, idx) in checkbox10" :key="idx">
										<input type="checkbox" name="checkbox17" ref="checkbox17" :id="'gshop2_' + idx" :value="item.value" v-model="params.globalAditChnl"/>
										<label :for="'gshop2_' + idx">{{ item.label }}</label>
									</div>
								</div>
							</dd>
						</dl>
						<dl class="selfdx-input-question">
							<dt>주로 판매했던 국가를 모두 선택하세요.</dt>
							<dd>
								<div class="control-flex">
									<div class="checkbox is-style" v-for="(item, idx) in checkbox11" :key="idx">
										<input type="checkbox" name="checkbox18" ref="checkbox18" :id="'global1_' + idx" :value="item.value" v-model="params.globalNowNation"/>
										<label :for="'global1_' + idx">{{ item.label }}</label>
									</div>
								</div>
							</dd>
						</dl>
						<dl class="selfdx-input-question">
							<dt>추가적으로 판매를 희망하는 국가를 모두 선택하세요.</dt>
							<dd>
								<div class="control-flex">
									<div class="checkbox is-style" v-for="(item, idx) in checkbox11" :key="idx">
										<input type="checkbox" name="checkbox19" ref="checkbox19" :id="'global2_' + idx" :value="item.value" v-model="params.globalAditNation"/>
										<label :for="'global2_' + idx">{{ item.label }}</label>
									</div>
								</div>
							</dd>
						</dl>
					</div>

					<!-- e: 해외 판매 경험 예 선택시 -->
					<!-- s: 해외 판매 경험 아니오 선택시 -->
					<div v-if="params.globalSaleYn === 'N'" class="selfdx-input-question">
						<dl class="selfdx-input-question">
							<dt>판매 경험이 없으시다면, 판매를 희망하는 채널을 모두 선택하세요.</dt>
							<dd>
								<div class="control-flex">
									<div class="checkbox is-style" v-for="(item, idx) in checkbox10" :key="idx">
										<input type="checkbox" name="checkbox20" ref="checkbox20" :id="'gshop3_' + idx" :value="item.value" v-model="params.globalHopeChnl"/>
										<label :for="'gshop3_' + idx">{{ item.label }}</label>
									</div>
								</div>
							</dd>
						</dl>
						<dl class="selfdx-input-question">
							<dt>판매를 희망하는 국가를 모두 선택하세요.</dt>
							<dd>
								<div class="control-flex">
									<div class="checkbox is-style" v-for="(item, idx) in checkbox11" :key="idx">
										<input type="checkbox" name="checkbox21" ref="checkbox21" :id="'global3_' + idx" :value="item.value" v-model="params.globalHopeation"/>
										<label :for="'global3_' + idx">{{ item.label }}</label>
									</div>
								</div>
							</dd>
						</dl>
					</div>
					<!-- e: 해외 판매 경험 아니오 선택시 -->
				</div>
			</section>
			<!-- e: section -->
			<div class="buttons selfdx-input-footer">
				<button type="button" class="button-default is-large is-gray is-rounded" @click="back">이전단계</button>
				<!-- <button type="button" class="button-default is-large is-gray is-rounded" @click="jump">이 단계 건너뛰기</button> -->
				<button type="button" class="button-default is-large is-secondary is-rounded" @click="complete">진단하기</button>
			</div>
		</div>
	</main>
</template>

<script>
import { MUT_SHOW_ALERT } from '@/store/_mut_consts';
import { Yn, Shop, Gshop, Global } from '../../../store/modules/diagn';
const inputData = {
	onlineSaleYn: '',
	onlineNowChnl: [],
	onlineNowTv: [],
	onlineNowMid: [],
	onlineNowLiv: [],
	onlineNowSub: [],
	onlineAditChnl: [],
	onlineAditTv: [],
	onlineAditMid: [],
	onlineAditLiv: [],
	onlineAditSub: [],
	onlineHopeChnl: [],
	onlineHopeTv: [],
	onlineHopeMid: [],
	onlineHopeLiv: [],
	onlineHopeSub: [],

	globalSaleYn: '',
	globalNowChnl: [],
	globalAditChnl: [],
	globalNowNation: [],
	globalAditNation: [],

	globalHopeChnl: [],
	globalHopeation: [],
}
export default {
	name: 'SelfDiagnosisInput4',
	components: {  },
	props:{
		filter:inputData
	},
	data: () => ({
		radio1: Yn,
		radio2: Yn,
		checkbox1: Shop,
		checkbox10: Gshop,
		checkbox11: Global,
		params: inputData,
		internalUpdate: false // 내부 업데이트 플래그 추가
	}),
	watch: {		
		filter:{
			handler(newVal, _) {
				this.updateData(newVal)
			},
			immediate: true,
			deep: true,
		},
		params:{
			handler(newVal, _) {
				this.sync(newVal)
			},
			immediate: true,
			deep: true,
		},
		'params.onlineNowChnl': {
			handler(newVal, oldVal) {
				this.checkboxVald(oldVal, newVal, 'onlineNowChnl')
			},
			immediate: true,
			deep: true,
		},
		'params.onlineNowTv': {
			handler(newVal, oldVal) {
				this.checkboxVald(oldVal, newVal, 'onlineNowTv')
			},
			immediate: true,
			deep: true,
		},
		'params.onlineNowMid': {
			handler(newVal, oldVal) {
				this.checkboxVald(oldVal, newVal, 'onlineNowMid')
			},
			immediate: true,
			deep: true,
		},
		'params.onlineNowLiv': {
			handler(newVal, oldVal) {
				this.checkboxVald(oldVal, newVal, 'onlineNowLiv')
			},
			immediate: true,
			deep: true,
		},
		'params.onlineNowSub': {
			handler(newVal, oldVal) {
				this.checkboxVald(oldVal, newVal, 'onlineNowSub')
			},
			immediate: true,
			deep: true,
		},
		'params.onlineAditChnl': {
			handler(newVal, oldVal) {
				this.checkboxVald(oldVal, newVal, 'onlineAditChnl')
			},
			immediate: true,
			deep: true,
		},
		'params.onlineAditTv': {
			handler(newVal, oldVal) {
				this.checkboxVald(oldVal, newVal, 'onlineAditTv')
			},
			immediate: true,
			deep: true,
		},
		'params.onlineAditMid': {
			handler(newVal, oldVal) {
				this.checkboxVald(oldVal, newVal, 'onlineAditMid')
			},
			immediate: true,
			deep: true,
		},
		'params.onlineAditLiv': {
			handler(newVal, oldVal) {
				this.checkboxVald(oldVal, newVal, 'onlineAditLiv')
			},
			immediate: true,
			deep: true,
		},
		'params.onlineAditSub': {
			handler(newVal, oldVal) {
				this.checkboxVald(oldVal, newVal, 'onlineAditSub')
			},
			immediate: true,
			deep: true,
		},
		'params.onlineHopeChnl': {
			handler(newVal, oldVal) {
				this.checkboxVald(oldVal, newVal, 'onlineHopeChnl')
			},
			immediate: true,
			deep: true,
		},
		'params.onlineHopeTv': {
			handler(newVal, oldVal) {
				this.checkboxVald(oldVal, newVal, 'onlineHopeTv')
			},
			immediate: true,
			deep: true,
		},
		'params.onlineHopeMid': {
			handler(newVal, oldVal) {
				this.checkboxVald(oldVal, newVal, 'onlineHopeMid')
			},
			immediate: true,
			deep: true,
		},
		'params.onlineHopeLiv': {
			handler(newVal, oldVal) {
				this.checkboxVald(oldVal, newVal, 'onlineHopeLiv')
			},
			immediate: true,
			deep: true,
		},
		'params.onlineHopeSub': {
			handler(newVal, oldVal) {
				this.checkboxVald(oldVal, newVal, 'onlineHopeSub')
			},
			immediate: true,
			deep: true,
		},
		'params.globalNowChnl': {
			handler(newVal, oldVal) {
				this.checkboxVald(oldVal, newVal, 'globalNowChnl')
			},
			immediate: true,
			deep: true,
		},
		'params.globalAditChnl': {
			handler(newVal, oldVal) {
				this.checkboxVald(oldVal, newVal, 'globalAditChnl')
			},
			immediate: true,
			deep: true,
		},
		'params.globalNowNation': {
			handler(newVal, oldVal) {
				this.checkboxVald(oldVal, newVal, 'globalNowNation')
			},
			immediate: true,
			deep: true,
		},
		'params.globalAditNation': {
			handler(newVal, oldVal) {
				this.checkboxVald(oldVal, newVal, 'globalAditNation')
			},
			immediate: true,
			deep: true,
		},
		'params.globalHopeChnl': {
			handler(newVal, oldVal) {
				this.checkboxVald(oldVal, newVal, 'globalHopeChnl')
			},
			immediate: true,
			deep: true,
		},
		'params.globalHopeation': {
			handler(newVal, oldVal) {
				this.checkboxVald(oldVal, newVal, 'globalHopeation')
			},
			immediate: true,
			deep: true,
		},
	
	},
	created(){
		
	},
	methods: {
		checkboxVald(oldVal, newVal, dataNm){
			if (this.internalUpdate) {
				this.internalUpdate = false;
				return;
			}
			const findOld = oldVal.find((val) => val === 'NONE')
			const findNew = newVal.find((val) => val === 'NONE')
			this.internalUpdate = true;
			if(!findOld && findNew){
				this.params[dataNm] = newVal.filter((val) => val === 'NONE') 
			}else{
				this.params[dataNm] = newVal.filter((val) => val !== 'NONE') 
			}
		},
		updateData(newVal){
			if (!newVal) return
			Object.assign(this.params, newVal);
			if (!this.params.onlineSaleYn) {
				this.params.onlineSaleYn = 'Y'
			}
			if (!this.params.globalSaleYn) {
				this.params.globalSaleYn = 'Y'
			}
		},
		back(){
			this.$emit('back');
		},
		validation() {
			if (!this.params.onlineSaleYn) {
				this.$store.commit(`common/${MUT_SHOW_ALERT}`, { 
					title: '온라인을 통한 판매경험을 선택해주세요.' 
					, yesfunc: () => {
						if(this.$refs.radio1.length) this.$refs.radio1[0].focus();
					},
				});
				return false;
			}else{
				if(this.params.onlineSaleYn === 'Y'){
					if(!this.params.onlineNowChnl.length){
						this.$store.commit(`common/${MUT_SHOW_ALERT}`, { 
							title: '주로 판매했던 채널(온라인 쇼핑몰)을 선택해주세요.' 
							, yesfunc: () => {
								if(this.$refs.checkbox1.length) this.$refs.checkbox1[0].focus();
							},
						});
						return false;
					}
					if(!this.params.onlineNowTv.length){
						this.$store.commit(`common/${MUT_SHOW_ALERT}`, { 
							title: '주로 판매했던 채널(TV 홈쇼핑)을 선택해주세요.' 
							, yesfunc: () => {
								if(this.$refs.checkbox2.length) this.$refs.checkbox2[0].focus();
							},
						});
						return false;
					}
					if(!this.params.onlineNowMid.length){
						this.$store.commit(`common/${MUT_SHOW_ALERT}`, { 
							title: '주로 판매했던 채널(미디어커머스)을 선택해주세요.'
							, yesfunc: () => {
								if(this.$refs.checkbox3.length) this.$refs.checkbox3[0].focus();
							},
						 });
						return false;
					}
					if(!this.params.onlineNowLiv.length){
						this.$store.commit(`common/${MUT_SHOW_ALERT}`, { 
							title: '주로 판매했던 채널(라이브커머스)을 선택해주세요.' 
							, yesfunc: () => {
								if(this.$refs.checkbox4.length) this.$refs.checkbox4[0].focus();
							},
						});
						return false;
					}
					if(!this.params.onlineNowSub.length){
						this.$store.commit(`common/${MUT_SHOW_ALERT}`, { 
							title: '주로 판매했던 채널(정기구독)을 선택해주세요.' 
							, yesfunc: () => {
								if(this.$refs.checkbox5.length) this.$refs.checkbox5[0].focus();
							},
						});
						return false;
					}
					if(!this.params.onlineAditChnl.length){
						this.$store.commit(`common/${MUT_SHOW_ALERT}`, { 
							title: '추가적으로 판매를 희망하는 온라인 채널(온라인 쇼핑몰)을 선택해주세요.' 
							, yesfunc: () => {
								if(this.$refs.checkbox6.length) this.$refs.checkbox6[0].focus();
							},
						});
						return false;
					}
					if(!this.params.onlineAditTv.length){
						this.$store.commit(`common/${MUT_SHOW_ALERT}`, { 
							title: '추가적으로 판매를 희망하는 온라인 채널(TV 홈쇼핑)을 선택해주세요.' 
							, yesfunc: () => {
								if(this.$refs.checkbox7.length) this.$refs.checkbox7[0].focus();
							},
						});
						return false;
					}
					if(!this.params.onlineAditMid.length){
						this.$store.commit(`common/${MUT_SHOW_ALERT}`, { 
							title: '추가적으로 판매를 희망하는 온라인 채널(미디어커머스)을 선택해주세요.' 
							, yesfunc: () => {
								if(this.$refs.checkbox8.length) this.$refs.checkbox8[0].focus();
							},
						});
						return false;
					}
					if(!this.params.onlineAditLiv.length){
						this.$store.commit(`common/${MUT_SHOW_ALERT}`, { 
							title: '추가적으로 판매를 희망하는 온라인 채널(라이브커머스)을 선택해주세요.' 
							, yesfunc: () => {
								if(this.$refs.checkbox9.length) this.$refs.checkbox9[0].focus();
							},
						});
						return false;
					}
					if(!this.params.onlineAditSub.length){
						this.$store.commit(`common/${MUT_SHOW_ALERT}`, { 
							title: '추가적으로 판매를 희망하는 온라인 채널(정기구독)을 선택해주세요.' 
							, yesfunc: () => {
								if(this.$refs.checkbox10.length) this.$refs.checkbox10[0].focus();
							},
						});
						return false;
					}
				}else if(this.params.onlineSaleYn === 'N'){
					if(!this.params.onlineHopeChnl.length){
						this.$store.commit(`common/${MUT_SHOW_ALERT}`, { 
							title: '판매를 희망하는 채널(온라인 쇼핑몰)을 선택해주세요.' 
							, yesfunc: () => {
								if(this.$refs.checkbox11.length) this.$refs.checkbox11[0].focus();
							},
						});
						return false;
					}
					if(!this.params.onlineHopeTv.length){
						this.$store.commit(`common/${MUT_SHOW_ALERT}`, { 
							title: '판매를 희망하는 채널(TV 홈쇼핑)을 선택해주세요.' 
							, yesfunc: () => {
								if(this.$refs.checkbox12.length) this.$refs.checkbox12[0].focus();
							},
						});
						return false;
					}
					if(!this.params.onlineHopeMid.length){
						this.$store.commit(`common/${MUT_SHOW_ALERT}`, { 
							title: '판매를 희망하는 채널(미디어커머스)을 선택해주세요.' 
							, yesfunc: () => {
								if(this.$refs.checkbox13.length) this.$refs.checkbox13[0].focus();
							},
						});
						return false;
					}
					if(!this.params.onlineHopeLiv.length){
						this.$store.commit(`common/${MUT_SHOW_ALERT}`, { 
							title: '판매를 희망하는 채널(라이브커머스)을 선택해주세요.' 
							, yesfunc: () => {
								if(this.$refs.checkbox14.length) this.$refs.checkbox14[0].focus();
							},
						});
						return false;
					}
					if(!this.params.onlineHopeSub.length){
						this.$store.commit(`common/${MUT_SHOW_ALERT}`, { 
							title: '판매를 희망하는 채널(정기구독)을 선택해주세요.' 
							, yesfunc: () => {
								if(this.$refs.checkbox15.length) this.$refs.checkbox15[0].focus();
							},
						});
						return false;
					}
				}
			}
			if (!this.params.globalSaleYn) {
				this.$store.commit(`common/${MUT_SHOW_ALERT}`, { 
					title: '해외 판매 경험을 선택해주세요.' 
					, yesfunc: () => {
						if(this.$refs.radio2.length) this.$refs.radio2[0].focus();
					},
				});
				return false;
			}else{
				if(this.params.globalSaleYn === 'Y'){
					if(!this.params.globalNowChnl.length){
						this.$store.commit(`common/${MUT_SHOW_ALERT}`, { 
							title: '해외 판매 시, 주로 판매했던 채널을 선택해주세요.' 
							, yesfunc: () => {
								if(this.$refs.checkbox16.length) this.$refs.checkbox16[0].focus();
							},
						});
						return false;
					}
					if(!this.params.globalAditChnl.length){
						this.$store.commit(`common/${MUT_SHOW_ALERT}`, { 
							title: '추가적으로 판매를 희망하는 채널을 선택해주세요.' 
							, yesfunc: () => {
								if(this.$refs.checkbox17.length) this.$refs.checkbox17[0].focus();
							},
						});
						return false;
					}
					if(!this.params.globalNowNation.length){
						this.$store.commit(`common/${MUT_SHOW_ALERT}`, { 
							title: '주로 판매했던 국가를 선택해주세요.' 
							, yesfunc: () => {
								if(this.$refs.checkbox18.length) this.$refs.checkbox18[0].focus();
							},
						});
						return false;
					}
					if(!this.params.globalAditNation.length){
						this.$store.commit(`common/${MUT_SHOW_ALERT}`, { 
							title: '추가적으로 판매를 희망하는 국가를 선택해주세요.' 
							, yesfunc: () => {
								if(this.$refs.checkbox19.length) this.$refs.checkbox19[0].focus();
							},
						});
						return false;
					}
				}else if(this.params.globalSaleYn === 'N'){
					if(!this.params.globalHopeChnl.length){
						this.$store.commit(`common/${MUT_SHOW_ALERT}`, { 
							title: '판매를 희망하는 채널을 선택해주세요.' 
							, yesfunc: () => {
								if(this.$refs.checkbox20.length) this.$refs.checkbox20[0].focus();
							},
						});
						return false;
					}
					if(!this.params.globalHopeation.length){
						this.$store.commit(`common/${MUT_SHOW_ALERT}`, { 
							title: '판매를 희망하는 국가를 선택해주세요.' 
							, yesfunc: () => {
								if(this.$refs.checkbox21.length) this.$refs.checkbox21[0].focus();
							},
						});
						return false;
					}
				}
			}
			
			return true;
		},
		sync() {
			this.$emit('sync', this.params);
		},
		jump() {
			// console.log('뎁스4 jump : ', this.params)
			this.$emit('complete', this.params, true);
		},
		complete() {
			const isVadt = this.validation();
			if (isVadt) {
				// console.log('뎁스4 : ', this.params)
				this.$emit('complete', this.params);
			}
		},
	},
};
</script>
