<template>
	<!-- begin::page-container(#회원가입) -->
	<main class="page-container selfdx-input" id="selfdxInput1">
		<div class="container">
			<!-- steps -->
			<steps :items="stepsItems" />
			<self-diagnosis-input1
				v-if="viewTab === 0"
				@back="back"
				@sync="sync"
				@complete="complete"
				:filter="{
					diagnGender: params.diagnGender
					, diagnAge: params.diagnAge
					, diagnSector: params.diagnSector
					, diagnSectorDetails: params.diagnSectorDetails
					, diagnExper: params.diagnExper
					, diagnAddr: params.diagnAddr
					, diagnAddrDetail: params.diagnAddrDetail
				}"

			/>
			<self-diagnosis-input2
				v-if="viewTab === 1"
				@back="back"
				@sync="sync"
				@complete="complete"
				:filter="{
					diagnCmpnyScale: params.diagnCmpnyScale
					, diagnSales: params.diagnSales
					, diagnCmpnyType: params.diagnCmpnyType
					, diagnCmpnyOfflineScore: params.diagnCmpnyOfflineScore
					, diagnCmpnyOnlineScore: params.diagnCmpnyOnlineScore
				}"
			/>
			<self-diagnosis-input3
				v-if="viewTab === 2"
				@back="back"
				@sync="sync"
				@complete="complete"
				:filter="{
					diagnDiffFields: params.diagnDiffFields
					, manageScore: params.manageScore
					, marketingScore: params.marketingScore
					, itemScore: params.itemScore
					, financeScore: params.financeScore
					, locationScore: params.locationScore
					, readyScore: params.readyScore
				}"
			/>
			<self-diagnosis-input4
			v-if="viewTab === 3"
				@back="back"
				@sync="sync"
				@complete="complete"
				:filter="{
					onlineSaleYn: params.onlineSaleYn
					
					, onlineNowChnl: params.onlineNowChnl
					, onlineNowTv: params.onlineNowTv
					, onlineNowMid: params.onlineNowMid
					, onlineNowLiv: params.onlineNowLiv
					, onlineNowSub: params.onlineNowSub

					, onlineAditChnl: params.onlineAditChnl
					, onlineAditTv: params.onlineAditTv
					, onlineAditMid: params.onlineAditMid
					, onlineAditLiv: params.onlineAditLiv
					, onlineAditSub: params.onlineAditSub

					, onlineHopeChnl: params.onlineHopeChnl
					, onlineHopeTv: params.onlineHopeTv
					, onlineHopeMid: params.onlineHopeMid
					, onlineHopeLiv: params.onlineHopeLiv
					, onlineHopeSub: params.onlineHopeSub

					, globalSaleYn: params.globalSaleYn

					, globalNowChnl: params.globalNowChnl
					, globalAditChnl: params.globalAditChnl
					, globalNowNation: params.globalNowNation
					, globalAditNation: params.globalAditNation

					, globalHopeChnl: params.globalHopeChnl
					, globalHopeation: params.globalHopeation
				}"
			/>

		</div>
	</main>
</template>

<script>
import Steps from '@/components/common/Steps';
import SelfDiagnosisInput1 from './SelfDiagnosisInput1.vue';
import SelfDiagnosisInput2 from './SelfDiagnosisInput2.vue';
import SelfDiagnosisInput3 from './SelfDiagnosisInput3.vue';
import SelfDiagnosisInput4 from './SelfDiagnosisInput4.vue';
import { mapGetters } from 'vuex';
import { MUT_SHOW_CONFIRM } from '@/store/_mut_consts';
import { Tabs } from '../../../store/modules/diagn';
import { MUT_CLOSE_ALERT, MUT_CLOSE_CONFIRM, MUT_SET_RETURN_ROUTE, MUT_SHOW_ALERT } from '../../../store/_mut_consts';
import { ACT_GET_SESSION } from '../../../store/_act_consts';
import { getItem, lengthCheck } from '../../../assets/js/utils';

export default {
	name: 'SelfDiagnosisInput',
	components: { 
		Steps
		, SelfDiagnosisInput1
		, SelfDiagnosisInput2
		, SelfDiagnosisInput3
		, SelfDiagnosisInput4
	 },
	computed: {
		...mapGetters('auth', ['isAuth', 'session']),
	},

	data: () => ({
		viewTab: 0,
		stepsItems: Tabs,
		params: {
			diagnGender: '',
			diagnAge: '',
			diagnSector: '',
			diagnSectorDetails: [],
			diagnExper: '',
			diagnAddr: '',
			diagnAddrDetail: '',
			diagnCmpnyScale: '',
			diagnSales: '',
			diagnCmpnyType: '',
			diagnCmpnyOfflineScore: 0,
			diagnCmpnyOnlineScore: 0,
			diagnDiffFields: [],
			manageScore: 0,
			marketingScore: 0,
			itemScore: 0,
			financeScore: 0,
			locationScore: 0,
			readyScore: 0,
			onlineSaleYn: '',

			onlineNowChnl: [],
			onlineNowTv: [],
			onlineNowMid: [],
			onlineNowLiv: [],
			onlineNowSub: [],
			onlineAditChnl: [],
			onlineAditTv: [],
			onlineAditMid: [],
			onlineAditLiv: [],
			onlineAditSub: [],
			onlineHopeChnl: [],
			onlineHopeTv: [],
			onlineHopeMid: [],
			onlineHopeLiv: [],
			onlineHopeSub: [],

			globalSaleYn: '',

			globalNowChnl: [],
			globalAditChnl: [],
			globalNowNation: [],
			globalAditNation: [],

			globalHopeChnl: [],
			globalHopeation: [],
		},
	}),
	watch: {		
		
	},
	created() {
		this.isLoginCheck()
	},
	beforeRouteLeave(to, from, next) {
		this.$store.commit(`common/${MUT_CLOSE_CONFIRM}`);
		if(to.fullPath === '/self-diagnosis') {
			this.$store.commit(`common/${MUT_SHOW_CONFIRM}`, {
					title: '기존에 선택한 데이터는 초기화 됩니다.<br />뒤로 돌아가시겠습니까?',
					html: true,
					showCancelButton: true,
					yesfunc: () => {
						next()
					},
					nofunc: () => {
						next(false)
					}
			})
		}else{
			next()
		}
	},
	methods: {
		async isLoginCheck() {
			const res = await this.$store.dispatch(`auth/${ACT_GET_SESSION}`);
			if (!lengthCheck(res)) {
				// 로그인 체크
				this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
					title: '회원가입 및 로그인이 필요합니다.',
					subText: '*자가진단 서비스는&nbsp;<strong>통합회원 대상 서비스</strong>입니다.',
					html: true,
					yesfunc: () => {
						this.$store.commit(`common/${MUT_SET_RETURN_ROUTE}`, this.$route.path);
						this.$router.push({ name: 'Login' });
					},
				});
			}else{
				// 로그인 된 상태여도 통합회원 체크
				const authItem = getItem(res)
				if( 
					Number(authItem.ssoSeq) === 0
					|| Number(authItem.userPattnCd) !== 400
				) {
					this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
						title: '통합회원만 사용이 가능합니다.',
						html: true,
						yesfunc: () => {
							this.$store.commit(`common/${MUT_SET_RETURN_ROUTE}`, this.$route.path);
							this.$router.push({ path: '/mypage2/home' });
						},
					});
				}
			}
		},
		back() {
			window.scrollTo(0, 0);
			const currentIdx = this.stepsItems.findIndex((step) => step.current)
			if(currentIdx === 0) return
			this.stepsItems = this.stepsItems.map((step, idx) => {
				if(idx === (currentIdx-1)){
					return {
						...step
						, complete: false
						, current: true
					}
				}else if(idx === currentIdx){
					return {
						...step
						, complete: false
						, current: false
					}
				}else{
					return {
						...step
						, current: false
					}
				}
			})

			this.viewTab = currentIdx-1

		},
		sync(newVal) {
			Object.assign(this.params, newVal);
		},
		complete(newVal, isJump = false) {
			Object.assign(this.params, newVal);
			const currentIdx = this.stepsItems.findIndex((step) => step.current)
			if(this.stepsItems.length === currentIdx+2) { // 결과 페이지는 스텝만 잇고, 실제 페이지가 없어서 +2를 한다

				this.$store.commit(`common/${MUT_SHOW_CONFIRM}`, {
					title: '자가진단을 진행하시겠습니까?',
					html: true,
					showCancelButton: true,
					yesfunc: () => {
						// console.log('최종 : ', this.params)

						if(isJump){
							this.params.onlineSaleYn = 'NONE'
							this.params.onlineHopeChnl = []
							this.params.onlineHopeTv = []
							this.params.onlineHopeMid = []
							this.params.onlineHopeLiv = []
							this.params.onlineHopeSub = []
							this.params.onlineNowChnl = []
							this.params.onlineNowTv = []
							this.params.onlineNowMid = []
							this.params.onlineNowLiv = []
							this.params.onlineNowSub = []
							this.params.onlineAditChnl = []
							this.params.onlineAditTv = []
							this.params.onlineAditMid = []
							this.params.onlineAditLiv = []
							this.params.onlineAditSub = []

							this.params.globalSaleYn = 'NONE'
							this.params.globalHopeChnl = []
							this.params.globalHopeation = []
							this.params.globalNowChnl = []
							this.params.globalAditChnl = []
							this.params.globalNowNation = []
							this.params.globalAditNation = []
						}else{
							this.params.onlineSaleYn = 'NONE'
							this.params.globalSaleYn = 'NONE'
							if(this.params.onlineSaleYn === 'Y'){
								this.params.onlineHopeChnl = []
								this.params.onlineHopeTv = []
								this.params.onlineHopeMid = []
								this.params.onlineHopeLiv = []
								this.params.onlineHopeSub = []
							}else if (this.params.onlineSaleYn === 'N'){
								this.params.onlineNowChnl = []
								this.params.onlineNowTv = []
								this.params.onlineNowMid = []
								this.params.onlineNowLiv = []
								this.params.onlineNowSub = []
								this.params.onlineAditChnl = []
								this.params.onlineAditTv = []
								this.params.onlineAditMid = []
								this.params.onlineAditLiv = []
								this.params.onlineAditSub = []
							}

							if(this.params.globalSaleYn === 'Y'){
								this.params.globalHopeChnl = []
								this.params.globalHopeation = []
							}else if(this.params.globalSaleYn === 'N'){
								this.params.globalNowChnl = []
								this.params.globalAditChnl = []
								this.params.globalNowNation = []
								this.params.globalAditNation = []
							}
						}
						localStorage.setItem('diagnosisData', JSON.stringify(this.params));
						this.$router.push({ name: 'SelfDiagnosisResult', params: { data: this.params  } });
					},
					nofunc: () => {
						
					},
				});



			}else{
				window.scrollTo(0, 0);
				this.viewTab = currentIdx+1
				// console.log('메인 : ', this.params)
				if(currentIdx === (this.stepsItems.length - 1)) return
				this.stepsItems = this.stepsItems.map((step, idx) => {
					if(idx === (currentIdx+1)){
						return {
							...step
							, complete: true
							, current: true
						}
					}else if(idx === currentIdx){
						return {
							...step
							, complete: true
							, current: false
						}
					}else{
						return {
							...step
							, current: false
						}
					}
				})
			}
		},
	},
};
</script>
