<template>
	<div class="steps-wrapper">
		<ol class="steps">
			<li class="steps-segment" :class="[{ 'is-complete': item.complete }, { 'is-active': item.current }]" v-for="(item, idx) in items" :key="idx">
				<div class="steps-inner">
					<span class="steps-marker">
						<span class="icon">{{ idx + 1 }}</span>
					</span>
					<div class="steps-content">
						<p class="heading">{{ item.text }}</p>
					</div>
				</div>
			</li>
		</ol>
	</div>
</template>

<script>
export default {
	name: 'Steps',
	props: {
		value: String,
		items: Array,
	},
	data: () => ({}),
	mounted() {},
};
</script>
