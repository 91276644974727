<template>
	<div>
		<div class="vslide-wrapper">
			<div class="vslide-inner">
				<div class="vslide-bg"><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i></div>
				<input ref="input" v-model="currentValue" type="range" :min="min" :max="max" :step="step" class="vslide" @input="onInput" />
				<ul class="vslide-text">
					<li>
						<span>오프라인</span>
						<em class="color-orange">{{ max - currentValue }}%</em>
					</li>
					<li>
						<span>온라인</span>
						<em class="color-orange">{{ currentValue }}%</em>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		value: {
			type: Number,
			required: true,
		},
		min: {
			type: Number,
			required: true,
		},
		max: {
			type: Number,
			required: true,
		},
		step: {
			type: Number,
			default: 1,
			required: false,
		},
	},
	data() {
		return {
			currentValue: this.value,
		};
	},
	methods: {
		onInput() {
			// this.currentValue is a string because HTML is weird
			this.$emit('input', parseInt(this.currentValue));
		},
	},
};
</script>
<style lang="scss">
.vslide-wrapper .vslide-inner {
	position: relative;
	width: 100%;
	padding: 20px 0;
	.vslide {
		-webkit-appearance: none;
		appearance: none;
		width: 100%;
		outline: none;
		height: 4px;
		background-color: transparent;
		&-bg {
			display: flex;
			position: absolute;
			z-index: -1;
			width: 100%;
			height: 4px;
			border-radius: 2px;
			background: rgba(0, 0, 0, 0.06);
			align-items: center;
			justify-content: space-between;

			i {
				display: flex;
				width: 10%;
				height: 4px;
				align-items: center;
				justify-content: space-between;
				&::before {
					content: '';
					display: block;
					width: 4px;
					height: 4px;
					background: rgba(0, 0, 0, 0.1);
					border-radius: 50%;
				}
				&:last-child {
					&::after {
						content: '';
						display: block;
						width: 4px;
						height: 4px;
						background: rgba(0, 0, 0, 0.1);
						border-radius: 50%;
					}
				}
			}
		}
		&-text {
			display: flex;
			margin-top: 10px;
			align-items: center;
			justify-content: space-between;
			li {
				display: block;
				span,
				em {
					display: block;
					font-size: 14px;
					font-weight: 500;
					line-height: 1.2;
					text-align: center;
				}
				span {
					margin-bottom: 4px;
					font-weight: 700;
				}
			}
		}
		&:hover {
			opacity: 1;
		}
		&::-webkit-slider-thumb {
			-webkit-appearance: none;
			appearance: none;
			width: 20px;
			height: 20px;
			background: #ff7300;
			cursor: pointer;
			outline: 10px solid rgba(255, 115, 0, 0.15);
			border-radius: 50%;
			box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
		}
		&::-moz-range-thumb {
			width: 20px;
			height: 20px;
			outline: 10px solid rgba(255, 115, 0, 0.15);
			border-radius: 50%;
			background: #ff7300;
			box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
			cursor: pointer;
		}
	}
}
</style>
