var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',{staticClass:"page-container selfdx-input",attrs:{"id":"selfdxInput1"}},[_c('div',{staticClass:"container"},[_c('steps',{attrs:{"items":_vm.stepsItems}}),(_vm.viewTab === 0)?_c('self-diagnosis-input1',{attrs:{"filter":{
				diagnGender: _vm.params.diagnGender
				, diagnAge: _vm.params.diagnAge
				, diagnSector: _vm.params.diagnSector
				, diagnSectorDetails: _vm.params.diagnSectorDetails
				, diagnExper: _vm.params.diagnExper
				, diagnAddr: _vm.params.diagnAddr
				, diagnAddrDetail: _vm.params.diagnAddrDetail
			}},on:{"back":_vm.back,"sync":_vm.sync,"complete":_vm.complete}}):_vm._e(),(_vm.viewTab === 1)?_c('self-diagnosis-input2',{attrs:{"filter":{
				diagnCmpnyScale: _vm.params.diagnCmpnyScale
				, diagnSales: _vm.params.diagnSales
				, diagnCmpnyType: _vm.params.diagnCmpnyType
				, diagnCmpnyOfflineScore: _vm.params.diagnCmpnyOfflineScore
				, diagnCmpnyOnlineScore: _vm.params.diagnCmpnyOnlineScore
			}},on:{"back":_vm.back,"sync":_vm.sync,"complete":_vm.complete}}):_vm._e(),(_vm.viewTab === 2)?_c('self-diagnosis-input3',{attrs:{"filter":{
				diagnDiffFields: _vm.params.diagnDiffFields
				, manageScore: _vm.params.manageScore
				, marketingScore: _vm.params.marketingScore
				, itemScore: _vm.params.itemScore
				, financeScore: _vm.params.financeScore
				, locationScore: _vm.params.locationScore
				, readyScore: _vm.params.readyScore
			}},on:{"back":_vm.back,"sync":_vm.sync,"complete":_vm.complete}}):_vm._e(),(_vm.viewTab === 3)?_c('self-diagnosis-input4',{attrs:{"filter":{
				onlineSaleYn: _vm.params.onlineSaleYn
				
				, onlineNowChnl: _vm.params.onlineNowChnl
				, onlineNowTv: _vm.params.onlineNowTv
				, onlineNowMid: _vm.params.onlineNowMid
				, onlineNowLiv: _vm.params.onlineNowLiv
				, onlineNowSub: _vm.params.onlineNowSub

				, onlineAditChnl: _vm.params.onlineAditChnl
				, onlineAditTv: _vm.params.onlineAditTv
				, onlineAditMid: _vm.params.onlineAditMid
				, onlineAditLiv: _vm.params.onlineAditLiv
				, onlineAditSub: _vm.params.onlineAditSub

				, onlineHopeChnl: _vm.params.onlineHopeChnl
				, onlineHopeTv: _vm.params.onlineHopeTv
				, onlineHopeMid: _vm.params.onlineHopeMid
				, onlineHopeLiv: _vm.params.onlineHopeLiv
				, onlineHopeSub: _vm.params.onlineHopeSub

				, globalSaleYn: _vm.params.globalSaleYn

				, globalNowChnl: _vm.params.globalNowChnl
				, globalAditChnl: _vm.params.globalAditChnl
				, globalNowNation: _vm.params.globalNowNation
				, globalAditNation: _vm.params.globalAditNation

				, globalHopeChnl: _vm.params.globalHopeChnl
				, globalHopeation: _vm.params.globalHopeation
			}},on:{"back":_vm.back,"sync":_vm.sync,"complete":_vm.complete}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }