<template>
	<!-- begin::page-container(#회원가입) -->
	<main class="page-container selfdx-input" id="selfdxInput2">
		<div class="container">
			<div class="selfdx-input-header">
				<h2 class="title">경영정보를 입력해 주세요.</h2>
			</div>

			<!-- s: section -->
			<section class="selfdx-input-section">
				<div class="selfdx-input-section__header">
					<h3 class="title">경영정보 입력</h3>
				</div>
				<div class="selfdx-input-section__content">
					<dl class="selfdx-input-question">
						<dt>고용인원 규모를 선택하세요.</dt>
						<dd>
							<div class="select-flex">
								<div class="select">
									<select name="" id="" ref="cmpnyScale" class="form-select is-rounded" v-model="params.diagnCmpnyScale">
										<option v-for="(item, idx) in cmpnyScales" :key="idx" :value="item.value">{{ item.label }}</option>
									</select>
								</div>
							</div>
						</dd>
					</dl>
					<dl class="selfdx-input-question">
						<dt>지난 달 매출 규모를 선택하세요.</dt>
						<dd>
							<div class="select-flex">
								<div class="select">
									<select name="" id="" ref="sales" class="form-select is-rounded" v-model="params.diagnSales">
										<option v-for="(item, idx) in sales" :key="idx" :value="item.value">{{ item.label }}</option>
									</select>
								</div>
							</div>
						</dd>
					</dl>
					<dl class="selfdx-input-question">
						<dt>사업 유형을 선택하세요.</dt>
						<dd>
							<div class="control-flex">
								<div class="radio is-style" v-for="(item, idx) in radio1" :key="idx">
									<input type="radio" name="radio1" ref="cmpnyType" :id="'cmpnyType_' + idx" :value="item.value" v-model="params.diagnCmpnyType" />
									<label :for="'cmpnyType_' + idx">{{ item.label }}</label>
								</div>
							</div>
						</dd>
					</dl>
					<dl class="selfdx-input-question" v-show="params.diagnCmpnyType === 'OFF_ONLINE'">
						<dt>온라인과 오프라인을 병행하신다면, 슬라이드바로 매출 비중을 선택하세요.</dt>
						<dd>
							<slider :min="0" :max="100" :step="10" :value="sliderVal" @input="sliderCallback" />
						</dd>
					</dl>
				</div>
			</section>
			<!-- e: section -->
			<div class="buttons selfdx-input-footer">
				<button type="button" class="button-default is-large is-gray is-rounded" @click="back">이전단계</button>
				<button type="button" class="button-default is-large is-secondary is-rounded" @click="complete">확인</button>
			</div>
		</div>
	</main>
</template>

<script>
import Slider from '@/components/common/Slider';
import { MUT_SHOW_ALERT } from '@/store/_mut_consts';
import { CmpnyScale, Sales, CmpnyType } from '../../../store/modules/diagn';

const inputData = {
	diagnCmpnyScale: '',
	diagnSales: '',
	diagnCmpnyType: '',
	diagnCmpnyOfflineScore: 0,
	diagnCmpnyOnlineScore: 0,
};
export default {
	name: 'SelfDiagnosisInput2',
	components: { Slider },
	props: {
		filter: inputData,
	},
	data: () => ({
		cmpnyScales: CmpnyScale,
		sales: Sales,
		radio1: CmpnyType,
		params: inputData,
		sliderVal: 50,
	}),
	watch: {
		filter: {
			handler(newVal, _) {
				this.updateData(newVal);
			},
			immediate: true,
			deep: true,
		},
		params: {
			handler(newVal, _) {
				this.sync(newVal);
			},
			immediate: true,
			deep: true,
		},
	},
	created() {},
	methods: {
		updateData(newVal) {
			if (!newVal) return;
			Object.assign(this.params, newVal);
			if (!this.params.diagnCmpnyScale) {
				this.params.diagnCmpnyScale = this.cmpnyScales[0].value;
			}
			if (!this.params.diagnSales) {
				this.params.diagnSales = this.sales[0].value;
			}
			if (this.params.diagnCmpnyOnlineScore) {
				this.sliderVal = this.params.diagnCmpnyOnlineScore;
			}
		},
		sliderCallback(val) {
			this.params.diagnCmpnyOfflineScore = 100 - val;
			this.params.diagnCmpnyOnlineScore = val;
		},
		validation() {
			if (!this.params.diagnCmpnyScale) {
				this.$store.commit(`common/${MUT_SHOW_ALERT}`, { 
					title: '고용인원 규모를 선택해주세요.'
					, yesfunc: () => {
						if(this.$refs.cmpnyScale.length) this.$refs.cmpnyScale[0].focus();
					},
				 });
				return false;
			}
			if (!this.params.diagnSales) {
				this.$store.commit(`common/${MUT_SHOW_ALERT}`, { 
					title: '지난 달 매출 규모를 선택해주세요.'
					, yesfunc: () => {
						if(this.$refs.sales.length) this.$refs.sales[0].focus();
					},
				 });
				return false;
			}
			if (!this.params.diagnCmpnyType) {
				this.$store.commit(`common/${MUT_SHOW_ALERT}`, { 
					title: '사업 유형을 온, 오프라인 중에서 선택해주세요.'
					, yesfunc: () => {
						if(this.$refs.cmpnyType.length) this.$refs.cmpnyType[0].focus();
					},
				 });
				return false;
			}
			return true;
		},
		back() {
			this.$emit('back');
		},
		sync() {
			this.settingScore();
			this.$emit('sync', this.params);
		},
		complete() {
			const isVadt = this.validation();
			if (isVadt) {
				this.settingScore();
				// console.log('뎁스2 : ', this.params)
				this.$emit('complete', this.params);
			}
		},
		settingScore() {
			if (this.params.diagnCmpnyType === 'OFFLINE') {
				this.params.diagnCmpnyOfflineScore = 100;
				this.params.diagnCmpnyOnlineScore = 0;
			} else if (this.params.diagnCmpnyType === 'ONLINE') {
				this.params.diagnCmpnyOfflineScore = 0;
				this.params.diagnCmpnyOnlineScore = 100;
			}
		},
	},
};
</script>
