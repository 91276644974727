<template>
	<!-- begin::page-container(#회원가입) -->
	<main class="page-container selfdx-input" id="selfdxInput1">
		<div class="container">
			<div class="selfdx-input-header">
				<h2 class="title">자가진단을 시작합니다.</h2>
			</div>
			<!-- s: section -->
			<section class="selfdx-input-section">
				<div class="selfdx-input-section__header">
					<h3 class="title">{{ session.userNm }} 님의 회원정보입니다.</h3>
				</div>
				<div class="selfdx-input-section__content">
					<dl class="selfdx-input-info" v-show="session.compNm">
						<dt>회사명</dt>
						<dd>{{ session.compNm }}</dd>
					</dl>
					<dl class="selfdx-input-info" v-show="session.prtlBrno">
						<dt>사업자등록번호</dt>
						<dd>{{ businessFormat(session.prtlBrno) }}</dd>
					</dl>
					<dl class="selfdx-input-info" v-show="session.ceoNm">
						<dt>대표자명</dt>
						<dd>{{ session.ceoNm }}</dd>
					</dl>
					<dl class="selfdx-input-info" v-show="session.openDay">
						<dt>설립일자</dt>
						<dd>{{ dateFormat(session.openDay, 'yyyy-MM-dd') }} </dd>
					</dl>
					<dl class="selfdx-input-info" v-show="industry">
						<dt>업종/업태</dt>
						<dd>{{ industry }}</dd>
					</dl>
					<dl class="selfdx-input-info" v-show="session.cmpyRpsAddr">
						<dt>주소</dt>
						<dd>{{ session.cmpyRpsAddr }}</dd>
					</dl>
				</div>
			</section>
			<!-- e: section -->
			<!-- s: section -->
			<section class="selfdx-input-section">
				<div class="selfdx-input-section__header">
					<!-- <i class="icon icon-selfdx-input1"></i> -->
					<h3 class="title">기본정보 입력</h3>
					<!-- <em class="desc">선택되어 있는 항목은 필요한 경우 수정하세요.</em> -->
				</div>
				<div class="selfdx-input-section__content">
					<dl class="selfdx-input-question">
						<dt>성별을 선택하세요.</dt>
						<dd>
							<div class="control-flex">
								<div class="radio is-style" v-for="(item, idx) in radio1" :key="idx">
									<input type="radio" name="radio1" ref="gender" :id="'gender_' + idx" :value="item.value" v-model="params.diagnGender" />
									<label :for="'gender_' + idx">{{ item.label }}</label>
								</div>
							</div>
						</dd>
					</dl>
					<dl class="selfdx-input-question">
						<dt>연령대를 선택하세요.</dt>
						<dd>
							<div class="control-flex">
								<div class="radio is-style" v-for="(item, idx) in radio2" :key="idx">
									<input type="radio" name="radio2" ref="age" :id="'age_' + idx" :value="item.value" v-model="params.diagnAge" />
									<label :for="'age_' + idx">{{ item.label }}</label>
								</div>
							</div>
						</dd>
					</dl>
					<dl class="selfdx-input-question">
						<dt>준비 중 또는 운영 중인 업종(상품 카테고리)을 선택하세요.</dt>
						<dd>
							<div class="control-flex">
								<div class="radio is-style" v-for="(item, idx) in radio3" :key="idx">
									<input type="radio" name="radio3" ref="sector" :id="'sector_' + idx" :value="item.value" v-model="params.diagnSector" />
									<label :for="'sector_' + idx">{{ item.label }}</label>
								</div>
							</div>

							<div class="control-flex is-small" v-if="radio3_.length > 0">
								<div class="checkbox is-style" v-for="(item, idx) in radio3_" :key="idx">
									<input type="checkbox" name="radio3_" ref="sectorDetails" :id="'sectorDetail_' + idx" :value="item.value" v-model="params.diagnSectorDetails" />
									<label :for="'sectorDetail_' + idx">{{ item.label }}</label>
								</div>
							</div>
						</dd>
					</dl>
					<dl class="selfdx-input-question">
						<dt>사업기간을 선택하세요</dt>
						<dd>
							<div class="control-flex">
								<div class="radio is-style" v-for="(item, idx) in radio5" :key="idx">
									<input type="radio" name="radio5" ref="exper" :id="'exper_' + idx" :value="item.value" v-model="params.diagnExper" />
									<label :for="'exper_' + idx">{{ item.label }}</label>
								</div>
							</div>
						</dd>
					</dl>
					<dl class="selfdx-input-question">
						<dt>점포(사무실) 위치를 선택하세요.</dt>
						<dd>
							<div class="select-flex">
								<div class="select">
									<select name="" id="" ref="addr" class="form-select is-rounded" v-model="params.diagnAddr">
										<option v-for="(item, idx) in areaList" :key="idx" :value="item.areaId">{{ item.areaName }}</option>
									</select>
								</div>
								<div class="select">
									<select name="" id="" ref="addrDetail" class="form-select is-rounded" v-model="params.diagnAddrDetail">
										<option v-for="(item, idx) in areaDtlList" :key="idx" :value="item.areaId">{{ item.areaName }}</option>
									</select>
								</div>
							</div>
						</dd>
					</dl>
				</div>
			</section>
			<!-- e: section -->
			<div class="buttons selfdx-input-footer">
				<button type="button" class="button-default is-large is-secondary is-rounded" @click="complete">확인</button>
			</div>
		</div>
	</main>
</template>

<script>
import { mapGetters } from 'vuex';
import { MUT_SHOW_ALERT } from '@/store/_mut_consts';
import { ymdToDateFormat, formatBusinessNumber } from '../../../assets/js/utils';
import { ACT_GET_DIAGN_AREA_LIST, ACT_GET_MSS_SPRT_BIZ_CMPNY_STATUS_LIST } from '@/store/_act_consts';
import { getItems } from '../../../assets/js/utils';
import { Gender, Age, Sector, Exper } from '../../../store/modules/diagn';

const inputData = {
	diagnGender: '',
	diagnAge: '',
	diagnSector: '',
	diagnSectorDetails: [],
	diagnExper: '',
	diagnAddr: '',
	diagnAddrDetail: '',
};
export default {
	name: 'SelfDiagnosisInput1',
	components: {},
	computed: {
		...mapGetters('auth', ['isAuth', 'session']),
	},
	props: {
		filter: inputData,
	},
	data: () => ({
		industry: '',
		areaList: [],
		areaDtlList: [],
		radio1: Gender,
		radio2: Age,
		radio3_: [],
		radio3: Sector,
		radio5: Exper,
		params: inputData,
	}),
	watch: {
		'session.prtlBrno': {
			handler(newVal, _) {
				if (newVal) {
					this.getMssSprtBizList();
				}
			},
			immediate: true,
			deep: true,
		},
		'params.diagnAddr': {
			handler(newVal, _) {
				this.getDiagnAreaList(newVal || 0);
			},
			immediate: true,
			deep: true,
		},
		'params.diagnSector': {
			handler(newVal, _) {
				this.params.diagnSectorDetails = [];
				const filterSectors = this.radio3.filter((item) => item.value === newVal);
				if (filterSectors.length) {
					this.radio3_ = filterSectors[0].sub;
				} else {
					this.radio3_ = [];
				}
			},
			immediate: true,
			deep: true,
		},
		filter: {
			handler(newVal, _) {
				this.updateData(newVal);
			},
			immediate: true,
			deep: true,
		},
		params: {
			handler(newVal, _) {
				this.sync(newVal);
			},
			immediate: true,
			deep: true,
		},
	},
	created() {
		this.getDiagnAreaList();
		this.getMssSprtBizList();
	},
	methods: {
		updateData(newVal) {
			if (!newVal) return;
			Object.assign(this.params, newVal);
		},
		async getDiagnAreaList() {
			await this.$store
				.dispatch(`diagn/${ACT_GET_DIAGN_AREA_LIST}`, 0)
				.then((res) => {
					if (200 == res.status.code) {
						this.areaList = getItems(res);
						if (!this.params.diagnAddr) {
							this.params.diagnAddr = this.areaList[0].areaId;
							this.getDiagnAreaListDetail(this.areaList[0].areaId);
						} else {
							this.getDiagnAreaListDetail(this.params.diagnAddr);
						}
					}
				})
				.catch((err) => {
					console.error(err);
				});
		},
		async getDiagnAreaListDetail(parentId) {
			await this.$store
				.dispatch(`diagn/${ACT_GET_DIAGN_AREA_LIST}`, parentId)
				.then((res) => {
					if (200 == res.status.code) {
						this.areaDtlList = getItems(res);
						this.params.diagnAddrDetail = this.areaDtlList[0].areaId;
					}
				})
				.catch((err) => {
					console.error(err);
				});
		},
		async getMssSprtBizList() {
			if (this.session.prtlBrno) {
				await this.$store
					.dispatch(`diagn/${ACT_GET_MSS_SPRT_BIZ_CMPNY_STATUS_LIST}`, this.session.prtlBrno)
					.then((res) => {
						if (200 == res.status.code) {
							const sprtBizs = getItems(res);
							if (sprtBizs.length) {
								this.industry = `${sprtBizs[0].induty} / ${sprtBizs[0].bizcnd}`;
							}
						}
					})
					.catch((err) => {
						console.error(err);
					});
			}
		},

		dateFormat(dt) {
			if (!dt) return dt;
			return ymdToDateFormat(dt, 'yyyy-MM-dd');
		},
		businessFormat(str) {
			if (!str) return str;
			return formatBusinessNumber(str);
		},
		validation() {
			if (!this.params.diagnGender) {
				this.$store.commit(`common/${MUT_SHOW_ALERT}`, { 
					title: '성별을 선택해주세요.' 
					, yesfunc: () => {
						if(this.$refs.gender.length) this.$refs.gender[0].focus();
					},
				});
				return false;
			}
			if (!this.params.diagnAge) {
				this.$store.commit(`common/${MUT_SHOW_ALERT}`, { 
					title: '연령대를 선택해주세요.' 
					, yesfunc: () => {
						if(this.$refs.age.length) this.$refs.age[0].focus();
					},
				});
				return false;
			}
			if (!this.params.diagnSector) {
				this.$store.commit(`common/${MUT_SHOW_ALERT}`, { 
					title: '준비 중 또는 운영 중인 업종(상품 카테고리)을 선택해주세요.' 
					, yesfunc: () => {
						if(this.$refs.sector.length) this.$refs.sector[0].focus();
					},
				});
				return false;
			}
			if (!this.params.diagnSectorDetails.length) {
				if (this.params.diagnSector !== 'ETC') {
					this.$store.commit(`common/${MUT_SHOW_ALERT}`, { 
						title: '준비 중 또는 운영 중인 업종(상품 카테고리의 상세 카테고리)을 선택해주세요.'
						, yesfunc: () => {
							if(this.$refs.sectorDetails.length) this.$refs.sectorDetails[0].focus();
						},
					 });
					return false;
				}
			}
			if (!this.params.diagnExper) {
				this.$store.commit(`common/${MUT_SHOW_ALERT}`, { 
					title: '사업기간을 선택해주세요.'
					, yesfunc: () => {
						if(this.$refs.exper.length) this.$refs.exper[0].focus();
					},
				 });
				return false;
			}
			if (!this.params.diagnAddr) {
				this.$store.commit(`common/${MUT_SHOW_ALERT}`, { 
					title: '점포(사무실) 위치를 선택해주세요.'
					, yesfunc: () => {
						if(this.$refs.addr.length) this.$refs.addr[0].focus();
					},
				 });
				return false;
			}
			if (!this.params.diagnAddrDetail) {
				this.$store.commit(`common/${MUT_SHOW_ALERT}`, { 
					title: '점포(사무실) 위치를 선택해주세요.'
					, yesfunc: () => {
						if(this.$refs.addrDetail.length) this.$refs.addrDetail[0].focus();
					},
				 });
				return false;
			}
			return true;
		},
		back() {
			this.$emit('back');
		},
		sync() {
			this.$emit('sync', this.params);
		},
		complete() {
			const isVadt = this.validation();
			if (isVadt) {
				// console.log('뎁스1 : ', this.params);
				this.$emit('complete', this.params);
			}
		},
	},
};
</script>
